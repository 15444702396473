import type { Chain } from "viem/chains";

import { CHAINID_MAP, CHAINKEY_MAP } from "@/constants/chains";
import { PoolConfig } from "@/types/core";
import { isFiniteNumber } from "@/util/check";
import { getEntries } from "@/util/core";

import { poolInfoByAddress, pools } from "../contracts/util/pools";

// todo refactor from @deprecated comments to eslint rule

/** @deprecated DO NOT USE CLIENTSIDE */
export const baseurl =
  process.env.API_BASEURL ?? "https://api.marginly.com/api";

/** @deprecated DO NOT USE CLIENTSIDE */
export const disableFeatures =
  process.env.DISABLE_FEATURES?.split(",").map((s) => s.trim()) ?? [];

const whitelist = process.env.CHAIN_WHITELIST?.split(",")
  .map(Number)
  .filter(isFiniteNumber);

export type PoolMap = Record<`0x${string}`, PoolConfig>;

export const poolsByChainId = pools.reduce<Record<number, PoolMap>>(
  (pools, basePoolInfo) => {
    const poolInfo = poolInfoByAddress[basePoolInfo.address];

    if (!poolInfo?.chainId) {
      return pools;
    }

    const prevPools = pools[poolInfo.chainId];

    return {
      ...pools,
      [poolInfo.chainId]: {
        ...prevPools,
        [basePoolInfo.address]: {
          ...poolInfo,
          ...basePoolInfo,
        },
      },
    };
  },
  {},
);

/** @deprecated DO NOT USE CLIENTSIDE */
export const { chains, initial: defaultChain } =
  whitelist?.reduce<{
    chains: Record<string, Chain>;
    initial: string | undefined;
  }>(
    (prev, id) => {
      const name = CHAINKEY_MAP[id];
      const chains = { ...prev.chains, [name || ""]: CHAINID_MAP[id] as Chain };
      return { chains, initial: prev.initial ?? name };
    },
    { chains: {}, initial: undefined },
  ) ??
  getEntries(poolsByChainId).reduce<{
    chains: Record<string, Chain>;
    initial: string | undefined;
  }>(
    (prev, [id]) => {
      const name = CHAINKEY_MAP[id];
      const chains = {
        ...prev.chains,
        [CHAINKEY_MAP[id] || ""]: CHAINID_MAP[id] as Chain,
      };
      return { chains, initial: prev.initial ?? name };
    },
    {
      initial: undefined,
      chains: {},
    },
  );
