import { getEntries, hasPrefix, stripPrefix } from "@/util/core";

import deployBlast from "../deploy/blastV15";
import { BasePoolConfig, TokenSide } from "./types";

const TOKEN_PREFIX = `token_`;
const MARGINLY_POOL_PREFIX = `marginlyPool_`;
const UNISWAP_POOL_PREFIX = `uniswapPool_`;
const UNISWAP_POOL_MOCK_PREFIX = `uniswapV3PoolMock_`;

// WIP: depoloy config parser
type TokenDeployKey = `${typeof TOKEN_PREFIX}${string}`;
type UniswapPoolDeployKey = `${typeof UNISWAP_POOL_PREFIX}${string}`;
type UniswapPoolMockDeployKey = `${typeof UNISWAP_POOL_MOCK_PREFIX}${string}`;
type MarginlyPoolDeployKey = `${typeof MARGINLY_POOL_PREFIX}${string}`;

interface Deployed {
  address: `0x${string}`;
  txHash?: `0x${string}`;
}

interface DeployConfig {
  [tokenDepl: TokenDeployKey]: Deployed;
  [uniswapMockPool: UniswapPoolMockDeployKey]: Deployed;
  [uniswapPool: UniswapPoolDeployKey]: Deployed;
  [marginlyPool: MarginlyPoolDeployKey]: Deployed;
}

export const isToken = hasPrefix(TOKEN_PREFIX);
export const tGetKey = stripPrefix(TOKEN_PREFIX);
const isMarginlyPool = hasPrefix(MARGINLY_POOL_PREFIX);
const mpGetKey = stripPrefix(MARGINLY_POOL_PREFIX);
const isUniswapMock = hasPrefix(UNISWAP_POOL_MOCK_PREFIX);
const isUniswap = hasPrefix(UNISWAP_POOL_PREFIX);
const upMockGetKey = stripPrefix(UNISWAP_POOL_MOCK_PREFIX);
const upGetKey = stripPrefix(UNISWAP_POOL_PREFIX);

const parseDeployConfig = <T extends {}>(
  deployConfig: DeployConfig,
  features?: T & { unwrap?: string },
) => {
  const pools: Record<string, Partial<BasePoolConfig> & T> = {};

  for (const [key, value] of getEntries(deployConfig)) {
    let pool: string | undefined;
    let address: `0x${string}` | undefined;
    let uniswapAddress: `0x${string}` | undefined;

    if (isMarginlyPool(key)) {
      pool = mpGetKey(key);
      address = value.address;
    } else if (isUniswap(key)) {
      pool = upGetKey(key);
      uniswapAddress = value.address;
    } else if (isUniswapMock(key)) {
      pool = upMockGetKey(key);
      uniswapAddress = value.address;
    }

    if (!pool) {
      continue;
    }

    if (!pools[pool]) {
      pools[pool] = {
        ...(features! ?? {}),
      };
    }

    if (address) {
      (pools[pool] as Partial<BasePoolConfig> & T).address = address;
    }

    if (uniswapAddress) {
      (pools[pool] as Partial<BasePoolConfig> & T).uniswapAddress =
        uniswapAddress;
    }

    if (features?.unwrap) {
      const [base, quote] = pool.split("-");

      if (base === features.unwrap) {
        // @ts-expect-error fixme types
        pools[pool].unwrap = "base";
      } else if (quote === features.unwrap) {
        // @ts-expect-error fixme types
        pools[pool].unwrap = "quote";
      } else {
        // @ts-expect-error fixme types
        pools[pool].unwrap = undefined;
      }
    }
  }

  return pools as Record<string, BasePoolConfig & T & { unwrap?: TokenSide }>;
};

/** @deprecated needs more work */
export const _blast = parseDeployConfig(deployBlast.contracts);
