import { defineChain } from "viem";
import type { Chain } from "viem/chains";
import * as _SUPPORTED_CHAINS from "viem/chains";

import { getEntries } from "@/util/core";

const blast = defineChain({
  id: 81457,
  name: "Blast",
  network: "blast",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: [
        "https://lively-wiser-shadow.blast-mainnet.quiknode.pro/b6508b73ed6528a3a5f0d549de2aa7efa6e2b0e4",
      ],
    },
    public: {
      http: [
        "https://rpc.blast.io",
        "https://blast.blockpi.network/v1/rpc/public",
        "https://rpc.ankr.com/blast",
      ],
    },
  },
  blockExplorers: {
    default: { name: "Blastscan", url: "https://blastscan.io/" },
  },
});

const blastSepolia = defineChain({
  id: 168587773,
  name: "Blast Sepolia Testnet",
  network: "blastSepolia",
  nativeCurrency: {
    decimals: 18,
    name: "ETH",
    symbol: "ETH",
  },
  rpcUrls: {
    default: { http: ["https://scn.equilab.io/blast/testnet/rpc"] },
    public: { http: ["https://sepolia.blast.io"] },
  },
  blockExplorers: {
    default: { name: "Blastscan", url: "https://testnet.blastscan.io" },
  },
  testnet: true,
});

export const x1Testnet = defineChain({
  id: 195,
  name: "X1 Testnet",
  network: "x1Testnet",
  nativeCurrency: {
    decimals: 18,
    name: "OKB",
    symbol: "OKB",
  },
  rpcUrls: {
    default: { http: ["https://x1testrpc.okx.com"] },
    public: { http: ["https://x1-testnet.blockpi.network/v1/rpc/public"] },
  },
  blockExplorers: {
    default: { name: "Oklink", url: "https://www.oklink.com/x1-test" },
  },
  testnet: true,
});

const SUPPORTED_CHAINS = {
  ..._SUPPORTED_CHAINS,
  // blast,
  // blastSepolia,
  // x1Testnet,
};

export const { CHAINID_MAP, CHAINKEY_MAP } = getEntries(
  SUPPORTED_CHAINS,
).reduce<{
  CHAINID_MAP: Record<number, Chain>;
  CHAINKEY_MAP: Record<number, string>;
}>(
  ({ CHAINID_MAP, CHAINKEY_MAP }, [key, value]) => {
    // @ts-ignore
    const id = value?.id;

    if (!id || !Number.isFinite(id)) {
      return { CHAINID_MAP, CHAINKEY_MAP };
    }

    return {
      CHAINID_MAP: { ...CHAINID_MAP, [id]: value },
      CHAINKEY_MAP: { ...CHAINKEY_MAP, [id]: key },
    };
  },
  { CHAINID_MAP: {}, CHAINKEY_MAP: {} },
);

export { SUPPORTED_CHAINS };

/** @deprecated move to env */
export const DEFAULT_CHAIN: keyof typeof SUPPORTED_CHAINS = "arbitrumGoerli";
