import { defaultChain } from "@/app/config";
import { CHAINKEY_MAP, DEFAULT_CHAIN } from "@/constants/chains";
import { normalizePtToken } from "@/util/common";
import { Partner } from "@/util/server/env";

export function getChainUrl(
  url: string,
  lastNetwork: string | undefined,
  partner?: Partner | undefined,
): URL {
  const baseUrl = process.env.APP_DEPLOY_URL ?? url;
  const lastId = Number(lastNetwork ?? -1);

  const networkName =
    lastId > 0 ? CHAINKEY_MAP[lastId] : defaultChain ?? DEFAULT_CHAIN;

  return new URL(
    `/${networkName}${partner ? "/trading-contest" : ""}`,
    baseUrl,
  );
}

const tokenInfo: Record<string, [string, string]> = {
  // not okay, is unwrap
  weth: ["ETH", "Ethereum"],
};

export const getTokenName = (
  token: { name: string; symbol: string } | undefined,
) => tokenInfo[token?.symbol.toLowerCase() ?? ""]?.[1] ?? token?.name;

/** @deprecated split rename/unwrap usage; look comments above */
export const getTokenSymbol = (
  token: string | undefined | null,
): string | undefined =>
  tokenInfo[token?.toLowerCase() ?? ""]?.[0] ?? normalizePtToken(token);
