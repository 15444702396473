import { _blast } from "@/contracts/util/config";
import { getEntries } from "@/util/core";

import { BasePoolConfig, PoolInfo } from "./types";

export const poolInfoByAddress: Record<`0x${string}`, PoolInfo> = {
  // pt-weeth-weth
  "0xcf848A30153506617B07207D2B3d054D4Fb72EBC": {
    maturityDate: "2024-06-27T00:00:00.000+00:00", // 1719446400 // https://arbiscan.io/address/0x1c27Ad8a19Ba026ADaBD615F6Bc77158130cfBE4#readContract#F8
    sparksPerQuotePerDay: 200,
    isPendle: true,
    isFarm: true,
    chainId: 42161,
    unwrap: "quote",
    flip: true,
    finished: true,
  },
  // pt-usde-usdc
  "0x79C37E226834Dc6BfB04Ba0b60f823515c32f50D": {
    maturityDate: "2024-08-29T00:00:00.000+00:00", // 1724889600 // https://arbiscan.io/address/0xad853EB4fB3Fe4a66CdFCD7b75922a0494955292#readContract#F8
    sparksPerQuotePerDay: 0.033,
    isPendle: true,
    isFarm: true,
    chainId: 42161,
    flip: true,
  },
  // pt-weeth-27jun2024-weth
  "0xE45FD040dCaF59276Be6AAC6A3C14e4707381AAC": {
    maturityDate: "2024-06-27T00:00:00.000+00:00", // 1719446400 // https://etherscan.io/address/0xc69Ad9baB1dEE23F4605a82b3354F8E40d1E5966#readContract#F8
    sparksPerQuotePerDay: 200,
    isPendle: true,
    isFarm: true,
    chainId: 1,
    unwrap: "quote",
    flip: true,
    finished: true,
  },
  // pt-weeth-26dec2024-weth
  "0xa77C2275C1F403056c7F73B44df69E374C299dd7": {
    maturityDate: "2024-12-26T00:00:00.000+00:00", // 1735171200 // https://etherscan.io/address/0x6ee2b5E19ECBa773a352E5B21415Dc419A700d1d#readContract#F8
    sparksPerQuotePerDay: 100,
    isPendle: true,
    isFarm: true,
    chainId: 1,
    unwrap: "quote",
    flip: true,
  },
  // pt-usde-25jul2024-usdt
  "0xbd4a9ca12c5f3c12d1378843c0b91585475aed51": {
    maturityDate: "2024-07-25T00:00:00.000+00:00", // 1721865600  // https://etherscan.io/address/0xa0021EF8970104c2d008F38D92f115ad56a9B8e1#readContract#F8
    sparksPerQuotePerDay: 0.033,
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
    finished: true,
  },
  // usde-usdb
  "0xc91D6032d72796b9a0558Ab9ef45C100c9fDe1f8": {
    sparksPerQuotePerDay: 0.033,
    chainId: 81457,
    flip: true,
  },
  // susde-usdb
  "0x12afF531E86f5E9763c6C7D0994AdEfB1a1f844c": {
    sparksPerQuotePerDay: 0.033,
    isFarm: true,
    chainId: 81457,
    flip: true,
  },
  // knox-eusd
  "0x3125e87D2630eb7DADD7F94e2fE05Bd21eE10C4f": {
    sparksPerQuotePerDay: 0.033,
    isFarm: true,
    chainId: 42161,
    flip: true,
  },
  // usde-usdc
  "0x9007A45304Ac6676CEf22ec68c870ae88Af60065": {
    sparksPerQuotePerDay: 0.033,
    chainId: 42161,
    flip: true,
  },
  // ethplus-weth
  "0xb1Af7B299ABBE4905Bbc80E614f64caCFEfBc96b": {
    sparksPerQuotePerDay: 100,
    isFarm: true,
    chainId: 42161,
    unwrap: "quote",
    flip: true,
  },
  // pt-ezeth-26sep2024-ezeth
  "0x44579419E975f4d59eaA0876f2EdCA7F2531821A": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 42161,
    flip: true,
  },
  // pt-weeth-26sep2024-weeth
  "0x3ad4F88aF401bf5F4F2fE35718139cacC82410d7": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    bonus: ["ether.fi", "eigenLayer", "turtleDao"],
    chainId: 42161,
    flip: true,
  },
  // pt-weeth-26dsep2024-weeth
  "0x4Cac44a1C50fea3F458f5F31529C0810AFcac497": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    bonus: ["ether.fi", "eigenLayer", "turtleDao"],
    chainId: 1,
    flip: true,
  },
  // pt-ezeth-26sep2024-ezeth
  "0x48f6E31CFe4da92C2470f8b797aa89a9460E9975": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
  },
  // pt-ezeth-26dec2024-ezeth
  "0xb34DfB03973e148ED800F369EdE84b92803116CA": {
    sparksPerQuotePerDay: 100,
    maturityDate: "2024-12-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
  },
  // // pt-unieth-26sep2024-unieth
  // "0x86DC30f6dBbb742463CE77f88926eff979892b22": {
  //   sparksPerQuotePerDay: 200,
  //   maturityDate: "2024-09-26T00:00:00.000+00:00",
  //   isPendle: true,
  //   isFarm: true,
  //   chainId: 1,
  //   flip: true,
  //   bonus: ["bedrock"],
  // },
  // pt-amphreth-26sep2024-amphreth
  "0xAc02A14F647029d2B2FF937032A2EDDfc6255A06": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
    bonus: ["mellow"],
  },
  // pt-pzeth-26sep2024-pzeth
  "0x51f95AAF94834b878eec18A812D840910370887f": {
    sparksPerQuotePerDay: 100,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
    bonus: ["mellow"],
  },
  // PT-rsETH-26SEP2024-rsETH
  "0x6128F06bF224F8407d5cBD7476D2BE0848aA05aF": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 42161,
    flip: true,
    bonus: ["kelp-miles", "eigenLayer"],
  },
  // PT-rsETH-26SEP2024-rsETH
  "0xa16C1F55129E4Af8288c005a27c8fE05591d24bE": {
    sparksPerQuotePerDay: 200,
    maturityDate: "2024-09-26T00:00:00.000+00:00",
    isPendle: true,
    isFarm: true,
    chainId: 1,
    flip: true,
    bonus: ["kelp-miles", "eigenLayer"],
  },
  // weth-usdc
  "0x87e711BcB9Ed1f2f6dec8fcC74cD2e0613D43b86": {
    chainId: 42161,
    unwrap: "base",
  },
  // arb-usdc
  "0x0F750fBb044037254b5843C6b4a715AA12876d94": {
    chainId: 42161,
  },
  // weth-usdc.e
  "0x53C08A5e2b7bc973d3d5Aee60373969E30e93B93": {
    chainId: 42161,
    unwrap: "base",
  },
  // arb-usdc.e
  "0x5ceB22fe09C7259b9dceef243615f180664BCE70": {
    chainId: 42161,
  },
  // wbtc-weth
  "0x99Cc2A68e121F2434db5C5D63670212F07f89ee8": {
    chainId: 42161,
    unwrap: "quote",
  },
  // gmx-weth
  "0x0637B18b5c5b7fe72F63a5511D2e90BEc7FC828E": {
    chainId: 42161,
    unwrap: "quote",
  },
  // pendle-weth
  "0x3adc0f25c7A23a626A67811c47d0A0DbE21773a4": {
    chainId: 42161,
    unwrap: "quote",
  },
  // rdnt-weth
  "0x82bC6A8dA5988E66676014cA99056Bd7A2f44dF2": {
    chainId: 42161,
    unwrap: "quote",
  },
  // weth-usdb
  "0x1f06e6e226bE4F0a66B7f8b1007997DC9De1eBC7": {
    chainId: 81457,
    flip: true,
    unwrap: "base",
  },
  // musd-usdb
  "0xb312D61915c878938fcE09D13DD3006c6835b3e5": {
    chainId: 81457,
    flip: true,
  },
  // weth-usdb
  "0xA24D9FB1CA3909DaaAF62503c766680d087E72B9": {
    chainId: 81457,
    flip: true,
    unwrap: "base",
  },
  // blast-weth
  "0x1E4dC872E7a2731fE91ECC654e4FEE4B65178c9d": {
    chainId: 81457,
    flip: true,
    unwrap: "quote",
  },
  // blast-usdb
  "0xEA3513719153Be844809C0Dc64c7E2aE4972Ba8A": {
    chainId: 81457,
    flip: true,
  },
};

const arbitrumPools: BasePoolConfig[] = [
  // pt-weeth-weth
  {
    address: "0xcf848A30153506617B07207D2B3d054D4Fb72EBC",
    uniswapAddress: "0x952083cde7aaa11ab8449057f7de23a970aa8472", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/arbitrum-v1.5-pendle/config.json#L84
  },
  // pt-usde-usdc
  {
    address: "0x79C37E226834Dc6BfB04Ba0b60f823515c32f50D",
    uniswapAddress: "0x2dfaf9a5e4f293bceede49f2dba29aacdd88e0c4", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/arbitrum-v1.5-pendle/config.json#L93C28-L93C70
  },
  // pt-ezeth-26sep2024-ezeth
  {
    address: "0x44579419E975f4d59eaA0876f2EdCA7F2531821A",
    uniswapAddress: "0x35f3db08a6e9cb4391348b0b404f493e7ae264c0", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // pt-weeth-26sep2024-weeth
  {
    address: "0x3ad4F88aF401bf5F4F2fE35718139cacC82410d7",
    uniswapAddress: "0xf9f9779d8ff604732eba9ad345e6a27ef5c2a9d6", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // weth-usdc
  {
    address: "0x87e711BcB9Ed1f2f6dec8fcC74cD2e0613D43b86",
    uniswapAddress: "0xC6962004f452bE9203591991D15f6b388e09E8D0",
  },
  // arb-usdc
  {
    address: "0x0F750fBb044037254b5843C6b4a715AA12876d94",
    uniswapAddress: "0xb0f6cA40411360c03d41C5fFc5F179b8403CdcF8",
  },
  // weth-usdc.e
  {
    address: "0x53C08A5e2b7bc973d3d5Aee60373969E30e93B93",
    uniswapAddress: "0xC31E54c7a869B9FcBEcc14363CF510d1c41fa443",
  },
  // arb-usdc.e
  {
    address: "0x5ceB22fe09C7259b9dceef243615f180664BCE70",
    uniswapAddress: "0xcDa53B1F66614552F834cEeF361A8D12a0B8DaD8",
  },
  // wbtc-weth
  {
    address: "0x99Cc2A68e121F2434db5C5D63670212F07f89ee8",
    uniswapAddress: "0x2f5e87C9312fa29aed5c179E456625D79015299c",
  },
  // gmx-weth
  {
    address: "0x0637B18b5c5b7fe72F63a5511D2e90BEc7FC828E",
    uniswapAddress: "0x1aEEdD3727A6431b8F070C0aFaA81Cc74f273882",
  },
  // pendle-weth
  {
    address: "0x3adc0f25c7A23a626A67811c47d0A0DbE21773a4",
    uniswapAddress: "0xdbaeB7f0DFe3a0AAFD798CCECB5b22E708f7852c",
  },
  // rdnt-weth
  {
    address: "0x82bC6A8dA5988E66676014cA99056Bd7A2f44dF2",
    uniswapAddress: "0x446BF9748B4eA044dd759d9B9311C70491dF8F29",
  },
  //knox-eusd
  {
    address: "0x3125e87D2630eb7DADD7F94e2fE05Bd21eE10C4f",
    uniswapAddress: "0x6f33daf91d2acae10f5cd7bbe3f31716ed123f1d",
  },
  //ethplus-weth
  {
    address: "0xb1Af7B299ABBE4905Bbc80E614f64caCFEfBc96b",
    uniswapAddress: "0x95f425c7d730eb7673fca272c2c305f48ed546c3",
  },
  //usde-usdc
  {
    address: "0x9007A45304Ac6676CEf22ec68c870ae88Af60065",
    uniswapAddress: "0xc23f308CF1bFA7efFFB592920a619F00990F8D74",
  },
  // PT-rsETH-26SEP2024-rsETH
  {
    address: "0x6128F06bF224F8407d5cBD7476D2BE0848aA05aF",
    uniswapAddress: "0xed99fc8bdb8e9e7b8240f62f69609a125a0fbf14",
  },
];

const mainnetPools: BasePoolConfig[] = [
  // pt-weeth-27jun2024-weth
  {
    address: "0xE45FD040dCaF59276Be6AAC6A3C14e4707381AAC",
    uniswapAddress: "0xf32e58f92e60f4b0a37a69b95d642a471365eae8", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L84
  },
  // pt-weeth-26dec2024-weth
  {
    address: "0xa77C2275C1F403056c7F73B44df69E374C299dd7",
    uniswapAddress: "0x7d372819240d14fb477f17b964f95f33beb4c704", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L93
  },
  // pt-usde-25jul2024-usdt
  {
    address: "0xbd4a9ca12c5f3c12d1378843c0b91585475aed51",
    uniswapAddress: "0x19588f29f9402bb508007feadd415c875ee3f19f", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // pt-weeth-26dsep2024-weeth
  {
    address: "0x4Cac44a1C50fea3F458f5F31529C0810AFcac497",
    uniswapAddress: "0xc8edd52d0502aa8b4d5c77361d4b3d300e8fc81c", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // pt-ezeth-26sep2024-ezeth
  {
    address: "0x48f6E31CFe4da92C2470f8b797aa89a9460E9975",
    uniswapAddress: "0xcdbd5ff3e03b6828db9c32e2131a60aba5137901", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // pt-ezeth-26dec2024-ezeth
  {
    address: "0xb34DfB03973e148ED800F369EdE84b92803116CA",
    uniswapAddress: "0xd8f12bcde578c653014f27379a6114f67f0e445f", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // // pt-unieth-26sep2024-unieth
  // {
  //   address: "0x86DC30f6dBbb742463CE77f88926eff979892b22",
  //   uniswapAddress: "0xbae2df4dfcd0c613018d6056a40077f2d1eff28a", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  // },
  // pt-pzeth-26sep2024-pzeth
  {
    address: "0x51f95AAF94834b878eec18A812D840910370887f",
    uniswapAddress: "0xd3bb297264bd6115ae163db4153038a79d78acba", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // pt-amphreth-26sep2024-amphreth
  {
    address: "0xAc02A14F647029d2B2FF937032A2EDDfc6255A06",
    uniswapAddress: "0x901e710c2f634131b36e3c68b072ed5db9250f3e", // https://github.com/eq-lab/marginly/blob/c04aa1a816415fa599d8d1a37d268cbdb51f49c4/packages/deploy/src/data/deploy/ethereum-v1.5-pendle/config.json#L102
  },
  // PT-rsETH-26SEP2024-rsETH
  {
    address: "0xa16C1F55129E4Af8288c005a27c8fE05591d24bE",
    uniswapAddress: "0x6b4740722e46048874d84306b2877600abcea3ae",
  },
];

export const pools: BasePoolConfig[] = [
  /* mainnet */
  ...arbitrumPools,
  ...mainnetPools,
  ...getEntries(_blast).map(([, v]) => v),
  /* testnet */
];
