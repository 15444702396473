import type { ApiResult } from "@/types/api";
import type { TransactionToken } from "@/types/common";

type TypePredicate<T> = (raw?: any) => raw is T;
type _Throws = undefined | ((raw?: any) => string);

export const parseByPredicate =
  <T, TT extends _Throws>(predicate: TypePredicate<T>, throws?: TT) =>
  (raw: any): TT extends undefined ? T | undefined : T => {
    if (!predicate(raw)) {
      if (throws) {
        throw new Error(throws(raw));
      }

      // @ts-ignore
      return undefined;
    }

    return raw;
  };

export const isApiSuccess = <T extends {}>(
  result?: ApiResult<boolean, T>,
): result is ApiResult<true, T> => Boolean(result?.success);

export const is0X = (raw?: any): raw is `0x${string}` =>
  typeof raw === "string" && raw?.indexOf("0x") === 0;

export const parse0x = parseByPredicate(
  is0X,
  (val) => `${val} is not a valid \`0x\${string}\``,
);

export const parse0xUnsafe = parseByPredicate(is0X);

export const isTransactionToken = (raw?: string): raw is TransactionToken =>
  ["base", "quote"].some((v) => v === raw);

export const hasKey = <T extends string>(
  key: T,
  obj?: any,
): obj is Record<T, any> => !!obj && Reflect.has(obj, key);

export const isFiniteNumber = (n?: any): n is number =>
  typeof n === "number" && Number.isFinite(n);

export const isValidInputAmount = (s: string | undefined) =>
  !!s && Number.isFinite(+s) && +s > 0;
