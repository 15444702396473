import { Time } from "lightweight-charts";

import { ZERO } from "@/constants/math";
import type { ApiSwapCandlesResponse } from "@/types/api";
import type { BgType, Candle } from "@/types/common";

// todo split file semantically

/** @deprecated ? */
export const pathnameToBgType = (pathname?: string): BgType | undefined => {
  if (pathname == "/") {
    return "home";
  }
};

/** @deprecated transformers */
export const toChartCandleSeries = (
  point: ApiSwapCandlesResponse["points"][number],
): Candle => ({
  time: point[0] as Time,
  open: ZERO.plus(point[1].open),
  high: ZERO.plus(point[1].high),
  low: ZERO.plus(point[1].low),
  close: ZERO.plus(point[1].close),
  volume: point[1].volumeBuy + point[1].volumeSell,
});

/** @deprecated math */
export const getPriceAggregates = (candles: Candle[] | undefined) => {
  if (!candles || candles.length === 0) return undefined;

  const diff = candles[candles.length - 1]?.close.minus(
    candles[0]?.open || ZERO,
  );
  const up = diff?.gt(0);
  const percent = diff
    ?.div(candles[candles.length - 1]?.close || ZERO)
    .times(100)
    .toNumber();
  return { diff, up, percent };
};

/** @deprecated */
export const getNetworkIcon = (networkId: number | undefined) => {
  const path = "/images/network";

  switch (networkId) {
    case 1:
      return `${path}/ethereum.svg`;
    case 195:
      return "/images/token/okb.svg";
    case 42161:
    case 421613:
    case 421614:
      return `${path}/arbitrum.svg`;
    case 80001:
      return `${path}/polygon.svg`;
    case 280:
      return `${path}/zksync.svg`;
    case 168587773:
    case 81457:
      return `${path}/blast.svg`;

    default:
      return "/images/token/eth.svg";
  }
};

export const normalizePtToken = (
  name: string | undefined | null,
): string | undefined => {
  if (name === undefined || name === null) {
    return;
  }

  if (!name.toLowerCase().startsWith("pt")) {
    return name;
  }

  const lastDashPos = name.lastIndexOf("-");
  const lastSpacePos = name.lastIndexOf(" ");

  // Determine the cut-off position, which should be the maximum of the two
  const cutOffPos = Math.max(lastDashPos, lastSpacePos);

  // If neither "-" nor " " is found, return the original string
  if (cutOffPos === -1) {
    return name;
  }

  // Return the string up to the cut-off position
  return name.substring(0, cutOffPos);
};
