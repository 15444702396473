export default {
  contracts: {
    "uniswapPool_weth-usdb": {
      address: "0xf00DA13d2960Cf113edCef6e3f30D92E52906537",
    },
    priceOracle_thrusterOracle: {
      address: "0x30C9A865F86adbA333B2390Ce69167D979f0db6f",
      txHash:
        "0x3c257cd9b2a5d8962ae8cd4ca1fc9b4e6c581c5977c740197df97498122237bc",
    },
    UniswapV3Adapter_0: {
      address: "0x8E650512B532c83262fdE1eda1c5A2A898B4DB00",
      txHash:
        "0x94315ef85636d2f0bce622a7abdf961db3a956eac7d78d3632ef2afdd3bd6a4d",
    },
    MarginlyRouter: {
      address: "0x1D17f7a1E9A53Cce6C89495AF1E3753c11Bf6DA2",
      txHash:
        "0x59ba10d086589d12861a07c3481546c854894f87f7c5ca643414def953e57e83",
    },
    marginlyPoolImplementation: {
      address: "0x1e36749E00229759dca262cB25Ad8d9B21bEB3F5",
      txHash:
        "0x64032206c2ec0ced3b05733f738a50a3e3c33e2a87e4ee0c6662edd452686a0f",
    },
    marginlyFactory: {
      address: "0x1768Faee0A63927FeB81100046f5D63BfE0f08dB",
      txHash:
        "0xa73abb62604f3891898d36e4ae2a7cecb29569857cbc5972bb3cf1386b766fc4",
    },
    "marginlyPool_weth-usdb": {
      address: "0x1f06e6e226bE4F0a66B7f8b1007997DC9De1eBC7",
      txHash:
        "0xd7e65ed8221f29268b74122eb8bd7d008ff2bce14bac054346e4db02d3f66430",
    },
    marginlyKeeperUniswapV3: {
      address: "0x953bCae95340b4f275c357a9d847E36617401f8e",
      txHash:
        "0xd3fd4f277c2ce600c8349d3c6879441d36fd8a23082fe63965de17ebb7854993",
    },
    "uniswapPool_musd-usdb": {
      address: "0x77e149741b4Aa14511324E4B554845063Ed038bd",
    },
    priceOracle_monoswapOracle: {
      address: "0x6e6AA06206E500f20d43CD7de5de925F5f8d8036",
      txHash:
        "0x567e43f2b4428175a9211dde053417fe44e178c38daefd277ecb79b2355642d9",
    },
    UniswapV3Adapter_21: {
      address: "0xF8D88A292B0afa85E5Cf0d1195d0D3728Cfd7070",
      txHash:
        "0x980c61986ee45fa2918e16b509de8728861ae7ebfe6f93f17c4615b56c616d2e",
    },
    UniswapV3Adapter_22: {
      address: "0x8b5e7a4Fc691eDCCB148E2fC657E22e8a0F3F310",
      txHash:
        "0x84a65bf4ae09a5a055fea3207bf90c7c1b80288eddc5688e0f166a1a2bb0850e",
    },
    "marginlyPool_musd-usdb": {
      address: "0xb312D61915c878938fcE09D13DD3006c6835b3e5",
      txHash:
        "0xb69aa7b60322741921185ce7e414617bdc5d7089bcaf35b8e3adbc6f4d41f449",
    },
    UniswapV2Adapter_23: {
      address: "0x904F8A3363e8D695870ae72045023Cf81cBabEB8",
      txHash:
        "0x3186f909d8d6d6839dfdda746f1164ea4c0126f12df1f1bcf84d5bcb15c61d5c",
    },
    "uniswapPool_weth-usdb-500": {
      address: "0x7f0DB0D77d0694F29c3f940b5B1F589FFf6EF2e0",
    },
    priceOracle_thrusterOracleV2: {
      address: "0x45Bc538D5FAaB8ac33a520c6acBD689575c2F082",
      txHash:
        "0x12253afcfd7953dbe41ef5d425f8cf1b89739b4c06cbffc12332c56963331b04",
    },
    "marginlyPool_weth-usdb-500": {
      address: "0xA24D9FB1CA3909DaaAF62503c766680d087E72B9",
      txHash:
        "0x31be1456ccce7d74a63ac54e0ac79f9cd8400b4eabfb858bb97e76612a69cdfd",
    },
    "uniswapPool_usde-usdb": {
      address: "0x8C1bb76510D6873a4A156A9Cb394E74A3783BDB5",
    },
    "marginlyPool_usde-usdb": {
      address: "0xc91D6032d72796b9a0558Ab9ef45C100c9fDe1f8",
      txHash:
        "0x744e512ab5bc6dbee3f582af0b1ef7c997b673f680cc82b535ffb2af9d63e637",
    },
    "uniswapPool_susde-usdb": {
      address: "0x5FA8702D9987B9Da17002c0Eb31B3a29d4A4ec2f",
    },
    "marginlyPool_susde-usdb": {
      address: "0x12afF531E86f5E9763c6C7D0994AdEfB1a1f844c",
      txHash:
        "0xca118cc1e11991fe8257f519715c624b9f122c00c908271f426c5339555cc0d6",
    },
    "uniswapPool_blast-weth": {
      address: "0x32fdF42095AaecfEA06F8ec26a361475F3603cB9",
    },
    "uniswapPool_blast-usdb": {
      address: "0xE3a5f46667461e35eeCe4E39e2177b438AF6B7f7",
    },
    "marginlyPool_blast-weth": {
      address: "0x1E4dC872E7a2731fE91ECC654e4FEE4B65178c9d",
      txHash:
        "0x46d6402b7c8439004a06a08159051b240f3eb71779b213fa49c32b55b7169b7d",
    },
    "marginlyPool_blast-usdb": {
      address: "0xEA3513719153Be844809C0Dc64c7E2aE4972Ba8A",
      txHash:
        "0x4ee8109ea441566bca25e331b5e8e894ea9d187b691b7ce6171429ae0f7054c5",
    },
  },
} as const;
