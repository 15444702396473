import { Camelize } from "@/types/core";

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export const capitalize = <T extends string>(s?: T) =>
  (s ? s[0]?.toUpperCase() + s.slice(1) : "") as Capitalize<T>;

export const decapitalize = <T extends string>(s?: T) =>
  s?.toLowerCase() as Lowercase<T>;

export const getEntries = <T extends {}, K extends keyof T>(
  obj?: T,
): Array<[K, T[K]]> => {
  return Object.entries(obj ?? {}) as unknown as Array<[K, T[K]]>;
};

type ValidKey<T> = T extends string | number | symbol ? T : never;

export const createMap = <T, K extends keyof T>(list: T[], field: K) => {
  return list.reduce((acc, item) => {
    const key = item[field];

    return {
      ...acc,
      // @ts-expect-error
      [key]: item,
    };
  }, {} as Record<ValidKey<T[K]>, T>);
};

export const isError = (e: unknown): e is Error =>
  !!e && Reflect.has(e, "message");

export const isKeyof = <T extends {}>(
  key: string | number | symbol,
  obj: T,
): key is keyof T => key in obj;

export const isNumStr = (num?: string | null): num is `${number}` => {
  if (!num) {
    return false;
  }

  const val = Number(num);
  return Number.isFinite(val);
};

export const hasPrefix =
  <T extends string>(prefix: T) =>
  (str?: string): str is `${T}${string}` =>
    str?.indexOf(prefix) === 0;

export const stripPrefix =
  <T extends string>(prefix: T) =>
  <S extends string>(str: `${T}${S}`): S => {
    const [, res] = str.split(prefix);
    if (!res) {
      throw new Error(`Unable to strip prefix ${prefix} from ${str}`);
    }

    return res as S;
  };

/** @description used to resolve type conflicts around __readonly__ types */
export const markWritable = <T>(data: T): DeepWriteable<T> => data;

export const parseNumStr = (num: string) => {
  if (!isNumStr(num)) {
    return `0` as `${number}`;
  }

  return num;
};

export const sleep = <T = unknown>(ms: number) =>
  new Promise<T>((resolve) => setTimeout(resolve, ms));

export const noop = () => undefined;

export const applyMapWith =
  <T, R>(mapperFn: (item: T) => R) =>
  (list?: T[]) =>
    list?.map(mapperFn);

export const getSeconds = () => Math.floor(Date.now() / 1000);

export const snakeToCamel = <T extends string>(str: T): Camelize<T> =>
  str
    .split("_")
    .map((s, i) => (i === 0 ? s : capitalize(s)))
    .join("") as Camelize<T>;

export const compareAddress = (a?: `0x${string}`, b?: `0x${string}`) =>
  a?.toLowerCase() === b?.toLowerCase();
